import { useState } from "react";
import logo from "../../assets/images/white.svg";
import "./Home.css";
import { API_URL } from "../../constants";


function Home() {

  const [value, setValue] = useState('');

  const handleClick = () => {
    console.log(API_URL)
    fetch(`${API_URL}/test`)
      .then((response) => { return response.text(); })
      .then(data => setValue(data));
  };

  return (
    <div className="Background">
      <img src={logo} className="Logo" alt="logo" />
      <h1 className="Title">Web Admin</h1>
      <button className="Connexion-btn" onClick={handleClick}>Se connecter</button>
      <p> { value } </p>
    </div>
  );
}

export default Home;
